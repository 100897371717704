import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer";
import Header from "../header/Header";
import NavMobile from "../nav/NavMobile";

function FeaturesScreen() {
  const [navMobile, setNavMobile] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000, delay: 300 });
  }, []);

  return (
    <>
      <Header setNavMobile={setNavMobile} />
      {/* Mobile Nav */}
      <div className={`${navMobile ? 'right-0' : '-right-full'} fixed z-10 top-0 h-full transition-all duration-200`}>
        <NavMobile setNavMobile={setNavMobile} />
      </div>
      <div className="bg-gray-200 min-h-screen py-10">
        <div className="container mx-auto px-4">
          {/* Top Text Section (Replaces Image) */}
          <div className="w-full p-4" data-aos="fade-down">
            <h1 className="text-4xl font-bold text-blue-600 mb-6 text-center">
              Explore the Key Features of PureTrade Hub
            </h1>
            <p className="text-lg text-gray-700 mb-8 text-center">
              PureTrade Hub is designed to empower investors with cutting-edge tools and services. Here's a look at what makes us stand out:
            </p>
          </div>
          
          {/* Features Section */}
          <div className="w-full p-4" data-aos="fade-up">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Feature 1 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="100">
                <img
                  src="https://paytmblogcdn.paytm.com/wp-content/uploads/2023/09/Blog_Paytm_Portfolio-Diversification-800x500.jpg"
                  alt="Diverse Investment"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  Diverse Investment Portfolio
                </h2>
                <p className="text-gray-700">
                  Choose from stocks, bonds, cryptocurrencies, and more. Flexibility is key.
                </p>
              </div>

              {/* Feature 2 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="200">
                <img
                  src="https://sarasanalytics.com/wp-content/uploads/2023/03/Realtime-Analytics.png"
                  alt="Real-time Analytics"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  Real-time Analytics
                </h2>
                <p className="text-gray-700">
                  Track your investments with our cutting-edge analytics, updated in real-time.
                </p>
              </div>

              {/* Feature 3 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="300">
                <img
                  src="https://useme.com/en/blog/wp-content/uploads/2023/08/secure-transactions-file-transfer-and-escrow-service-4.jpg"
                  alt="Secure Transactions"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  Secure Transactions
                </h2>
                <p className="text-gray-700">
                  Your security is our priority. We use top-notch encryption to protect your assets.
                </p>
              </div>

              {/* Feature 4 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="400">
                <img
                  src="https://simplycontact.com/wp-content/uploads/2022/09/24_7-customer-support-1024x576.jpeg"
                  alt="24/7 Support"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  24/7 Support
                </h2>
                <p className="text-gray-700">
                  Our dedicated team is available around the clock to assist you with any queries.
                </p>
              </div>

              {/* Feature 5 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="500">
                <img
                  src="https://www.mintos.com/blog/wp-content/uploads/2024/04/automate-investments.png"
                  alt="Automated Investments"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  Automated Investments
                </h2>
                <p className="text-gray-700">
                  Set your preferences and let our automated system grow your wealth on autopilot.
                </p>
              </div>

              {/* Feature 6 */}
              <div className="text-center" data-aos="fade-up" data-aos-delay="600">
                <img
                  src="https://d32ijn7u0aqfv4.cloudfront.net/wp/wp-content/uploads/raw/SOIN20052_780x440.jpeg"
                  alt="Low Fees, High Returns"
                  className="mx-auto mb-4 w-full h-48 object-cover rounded-md"
                />
                <h2 className="text-2xl font-semibold text-blue-600 mb-2">
                  Low Fees, High Returns
                </h2>
                <p className="text-gray-700">
                  Maximize your returns with our low-fee structure, designed for smart investors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FeaturesScreen;
