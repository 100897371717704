import React from 'react';

const EmailVerification = () => {
  return (
    <div className="min-h-screen bg-blue-50 flex items-center justify-center p-4">
      <div className="bg-white max-w-md w-full rounded-lg shadow-lg p-6">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-blue-600">Email Verification</h1>
        </div>
        {/* <p className="text-black text-center mb-6">
          Hello <span className="text-blue-600 font-semibold">User</span>,
        </p> */}
        <p className="text-black text-center mb-6">
          Thank you for registering! Please verify your email address by clicking the button below.
        </p>
        <div className="flex justify-center mb-6">
          <button className="bg-green-500 text-white font-bold py-2 px-6 rounded-md shadow hover:bg-green-600 transition duration-300">
            Verify Email
          </button>
        </div>
        <p className="text-center text-gray-600">
          If you did not create an account, no further action is required.
        </p>
        <div className="flex justify-center mt-6">
          <hr className="border-t border-gray-300 w-full" />
        </div>
        <p className="text-gray-500 text-center mt-6">
          © 2024 PureTradeHub. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default EmailVerification;
