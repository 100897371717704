// import './App.css';
import Aos from 'aos';
import 'aos/dist/aos.css'


import { useEffect, useState } from 'react';
import Header from '../header/Header';
import Hero from '../hero/Hero';
import NavMobile from '../nav/NavMobile';
import Stats from '../stats/Stats';
import Why from '../stats/Why';
import Calculate from '../sections/Calculate';
import Trade from '../sections/Trade';
import Features from '../sections/Features';
import NewsLetter from '../sections/NewsLetter';
import Footer from '../sections/Footer';
import Sidebar from '../Sidebar/Sidebar';

function Home() {
  //state management
  const [navMobile, setNavMobile] = useState(false);
  //aos init
  useEffect(() => {
    Aos.init({
      duration: 2500,
      delay: 400,
    });
  }
  );

  useEffect(() => {
    // Function to load the TradingView script
    const loadScript = () => {
      if (!document.getElementById("tradingview-widget-script")) {
        const script = document.createElement("script");
        script.id = "tradingview-widget-script";
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbols: [
            { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
            { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
            { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
            { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
            { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
            { description: "Notcoin", proName: "OKX:NOTUSDT" },
            { description: "USBOND", proName: "TVC:US10Y" },
          ],
          showSymbolLogo: false,
          isTransparent: false,
          displayMode: "regular",
          colorTheme: "dark",
          locale: "en",
        });
        document
          .querySelector(".tradingview-widget-container__widget")
          .appendChild(script);
      }
    };

    loadScript();
  }, []);

  return (
    <div className='overflow-hidden'>
      <div className="tradingview-widget-container mb-4  ">
          <div className="tradingview-widget-container__widget"></div>
        </div>
      <Header setNavMobile={setNavMobile} />
      <Hero />
      {/* Mobile Nav */}
      <div className={`${navMobile ? 'right-0' : '-right-full'} fixed z-10 top-0 h-full transition-all duration-200`}>
        <NavMobile setNavMobile={setNavMobile} />
      </div>
      <div id="stats"><Stats /></div>
      <div id="why"><Why /></div>
      <div id="calculate"><Calculate /></div>
      <div id="trade"><Trade /></div>
      <div id="features"><Features /></div>
      <div id="newsletter"><NewsLetter /></div>
      <Footer />

    </div>
  );
}

export default Home;