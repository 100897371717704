import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import NavMobile from '../nav/NavMobile';
import Aos from 'aos';
import Footer from './Footer';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send to backend or email service)
    setSubmitted(true);
  };
  useEffect(() => {
    Aos.init({ duration: 2000, delay: 300 });
  }, []);

  return (
    <>
    <Header setNavMobile={setNavMobile} />
    {/* Mobile Nav */}
    <div className={`${navMobile ? 'right-0' : '-right-full'} fixed z-10 top-0 h-full transition-all duration-200`}>
      <NavMobile setNavMobile={setNavMobile} />
    </div>
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Contact Us</h1>
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          {submitted ? (
            <div className="text-center">
              <h2 className="text-2xl font-semibold text-green-600">Thank you for contacting us!</h2>
              <p className="text-gray-700 mt-4">We'll get back to you as soon as possible.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>

        {/* Social Media Links Section */}
        <div className="text-center mt-10">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Follow Us</h2>
          <div className="flex justify-center space-x-6">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
              aria-label="Facebook"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M22.675 0H1.325C.594 0 0 .593 0 1.324v21.351C0 23.405.593 24 1.325 24H12.81v-9.294H9.691V10.82h3.119V8.406c0-3.097 1.893-4.786 4.657-4.786 1.325 0 2.464.099 2.795.143v3.24h-1.917c-1.504 0-1.795.716-1.795 1.764v2.31h3.588l-.468 3.886h-3.12V24h6.116C23.405 24 24 23.407 24 22.675V1.324C24 .593 23.407 0 22.675 0z" />
              </svg>
            </a>

            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-600"
              aria-label="Twitter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557a9.83 9.83 0 0 1-2.828.775 4.93 4.93 0 0 0 2.165-2.723 9.865 9.865 0 0 1-3.127 1.195 4.918 4.918 0 0 0-8.38 4.482A13.955 13.955 0 0 1 1.671 3.149a4.917 4.917 0 0 0 1.523 6.574A4.903 4.903 0 0 1 .964 9.097v.062a4.914 4.914 0 0 0 3.946 4.818 4.897 4.897 0 0 1-2.212.084 4.919 4.919 0 0 0 4.59 3.417 9.868 9.868 0 0 1-6.102 2.104c-.396 0-.787-.023-1.174-.069a13.945 13.945 0 0 0 7.548 2.213c9.058 0 14.01-7.513 14.01-14.03 0-.213-.004-.426-.014-.637A10.025 10.025 0 0 0 24 4.557z" />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-900"
              aria-label="LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3C3.35 3 2 4.34 2 5.98 2 7.61 3.35 9 4.98 9h.04c1.63 0 2.98-1.39 2.98-3.02C8 4.34 6.65 3 5.02 3zm.04 9h-4v12h4V12zm6 0h-4v12h4v-6.09c0-1.62 1.31-2.94 2.94-2.94s2.94 1.31 2.94 2.94V24h4v-7.91c0-3.62-2.94-6.56-6.56-6.56S11.04 8.38 11.04 12zm12-12h-4V0h4v4z" />
              </svg>
            </a>

            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 hover:text-pink-700"
              aria-label="Instagram"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.849.07 1.17.057 1.97.248 2.426.415a4.916 4.916 0 0 1 1.746 1.146 4.918 4.918 0 0 1 1.147 1.746c.167.456.358 1.256.415 2.426.058 1.265.07 1.645.07 4.849 0 3.204-.012 3.584-.07 4.849-.057 1.17-.248 1.97-.415 2.426a4.91 4.91 0 0 1-1.147 1.746 4.91 4.91 0 0 1-1.746 1.147c-.456.167-1.256.358-2.426.415-1.265.058-1.645.07-4.849.07-3.204 0-3.584-.012-4.849-.07-1.17-.057-1.97-.248-2.426-.415a4.916 4.916 0 0 1-1.746-1.146 4.916 4.916 0 0 1-1.147-1.746c-.167-.456-.358-1.256-.415-2.426-.058-1.265-.07-1.645-.07-4.849 0-3.204.012-3.584.07-4.849.057-1.17.248-1.97.415-2.426a4.918 4.918 0 0 1 1.146-1.746 4.918 4.918 0 0 1 1.746-1.147c.456-.167 1.256-.358 2.426-.415C8.416 2.175 8.796 2.163 12 2.163zm0-2.163C8.737 0 8.332.014 7.053.072 5.72.13 4.68.312 3.79.594a7.072 7.072 0 0 0-2.643 1.731A7.072 7.072 0 0 0 .594 4.79C.312 5.68.13 6.72.072 7.053.014 8.332 0 8.737 0 12c0 3.263.014 3.668.072 4.947.058 1.333.24 2.373.522 3.263a7.072 7.072 0 0 0 1.731 2.643 7.072 7.072 0 0 0 2.643 1.731c.89.281 1.93.463 3.263.522 1.28.058 1.684.072 4.947.072 3.263 0 3.668-.014 4.947-.072 1.333-.058 2.373-.24 3.263-.522a7.072 7.072 0 0 0 2.643-1.731 7.072 7.072 0 0 0 1.731-2.643c.281-.89.463-1.93.522-3.263.058-1.28.072-1.684.072-4.947 0-3.263-.014-3.668-.072-4.947-.058-1.333-.24-2.373-.522-3.263a7.072 7.072 0 0 0-1.731-2.643A7.072 7.072 0 0 0 20.21.594c-.89-.281-1.93-.463-3.263-.522C15.668.014 15.263 0 12 0zM12 5.838A6.162 6.162 0 0 0 5.838 12 6.162 6.162 0 0 0 12 18.162 6.162 6.162 0 0 0 18.162 12 6.162 6.162 0 0 0 12 5.838zm0 10.126A3.964 3.964 0 0 1 8.036 12 3.964 3.964 0 0 1 12 8.036 3.964 3.964 0 0 1 15.964 12 3.964 3.964 0 0 1 12 15.964zm6.406-10.845a1.44 1.44 0 1 1 0-2.88 1.44 1.44 0 0 1 0 2.88z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    </>
  );
}

export default ContactUs;
