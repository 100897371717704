import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="flex items-center font-medium">
      {/* link button */}
      <Link to="/login" className="hover:text-blue transition">
        Login
      </Link>
      {/* Seperator */}
      <span className="mx-6 text-slate-300 font-thin">|</span>
      {/* Register button */}
      <Link to='/signup' className="py-2 bg-blue-500 px-8 hover:bg-blue-600 text-white rounded-full text-base ">
        Register
      </Link>
    </div>
  );
};

export default Login;
