import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../Contex/UserContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseconfig"; // Make sure to import your Firebase auth instance

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user } = useUser(); // Get user details from context
  const navigate = useNavigate(); // Hook to programmatically navigate
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Navigate to the login page or any other page after successful logout
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
      // Optionally, show a message to the user
    }
  };

  return (
    <div className=" min-h-screen font-[Poppins]">
      <span
        className="absolute text-white text-4xl top-5 left-4 cursor-pointer"
        onClick={toggleSidebar}
      >
        <i className="bi bi-filter-left px-2 bg-gray-900 rounded-md"></i>
      </span>
      <div
        className={`sidebar fixed top-0 bottom-0 lg:left-0 ${
          isOpen ? "left-0" : "left-[-300px]"
        } duration-1000 p-2 w-[300px] overflow-y-auto text-center bg-gray-900 shadow h-screen`}
      >
        <div className="text-gray-100 text-xl">
          <div className="p-2.5 mt-1 flex items-center rounded-md">
            <i className="bi bi-bar-chart px-2 py-1 bg-blue-600 rounded-md"></i>
            <Link
              to="/"
              className="text-[15px] ml-3 text-xl text-gray-200 font-bold"
            >
              Pure TradeHub
            </Link>
            <i
              className="bi bi-x ml-20 cursor-pointer lg:hidden"
              onClick={toggleSidebar}
            ></i>
          </div>
          <hr className="my-2 text-gray-600" />
          <div>
            <Link
              to="/Dashboard"
              className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600"
            >
              <i className="bi bi-house-door-fill"></i>
              <span className="text-[15px] ml-4 text-gray-200">Home</span>
            </Link>
            <Link
              to="/InvestmentTracker"
              className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600"
            >
              <i className="bi bi-bookmark-fill"></i>
              <span className="text-[15px] ml-4 text-gray-200">
                Investments
              </span>
            </Link>
            <hr className="my-4 text-gray-600" />

            <>
            <Link to='/EmailVerification'>
              <div className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600">
                <i className="bi bi-envelope-fill"></i>
                <span className="text-[15px] ml-4 text-gray-200">Messages</span>
              </div>
            </Link>
              {user.role === "admin" && ( 
                <>
                  <div
                    className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600"
                    onClick={toggleDropdown}
                  >
                    <i className="bi bi-chat-left-text-fill"></i>
                    <div className="flex justify-between w-full items-center">
                      <span className="text-[15px] ml-4 text-gray-200">
                        Admin
                      </span>
                      <span
                        className={`text-sm ${
                          isDropdownOpen ? "" : "rotate-180"
                        }`}
                        id="arrow"
                      >
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </div>
                  </div>

                  {isDropdownOpen && (
                    <div
                      className="leading-7 text-left text-sm font-thin mt-2 w-4/5 mx-auto"
                      id="submenu"
                    >
                      <Link to="/UsersList">
                        <h1 className="cursor-pointer p-2 hover:bg-gray-700 rounded-md mt-1">
                          Users
                        </h1>
                      </Link>
                      <Link to="/InvestmentsTable">
                        <h1 className="cursor-pointer p-2 hover:bg-gray-700 rounded-md mt-1">
                          Transactions
                        </h1>
                      </Link>
                      
                    </div>
                  )}
                </>
               )}
            </>

            <div
              className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600"
              onClick={handleLogout}
            >
              <i className="bi bi-box-arrow-in-right"></i>
              <span className="text-[15px] ml-4 text-gray-200">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
