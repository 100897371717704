import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import TradingViewMarketOverviewWidget from "../Widgets/TradingViewMarketOverviewWidget";
import { Modal, Button, Table, Tag } from "antd";
import TradingViewWidgetNews from "../Widgets/NewsTradingViewWidget";
import { useUser } from "../Contex/UserContext"; // Import the UserContext
import userimg from "../../assets/img/user.png";
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebaseconfig";
import { Link, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { signOut } from "firebase/auth";

const Dashboard = () => {
  const { user } = useUser(); // Get user details from context
  const [investments, setInvestments] = useState([]); // State for investments
  const navigate = useNavigate();

  useEffect(() => {
    // Function to load the TradingView script
    const loadScript = () => {
      if (!document.getElementById("tradingview-widget-script")) {
        const script = document.createElement("script");
        script.id = "tradingview-widget-script";
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbols: [
            { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
            { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
            { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
            { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
            { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
            { description: "Notcoin", proName: "OKX:NOTUSDT" },
            { description: "USBOND", proName: "TVC:US10Y" },
          ],
          showSymbolLogo: false,
          isTransparent: false,
          displayMode: "regular",
          colorTheme: "dark",
          locale: "en",
        });
        document
          .querySelector(".tradingview-widget-container__widget")
          .appendChild(script);
      }
    };

    loadScript();
  }, []);

  useEffect(() => {
    // Function to fetch investments data based on user ID
    const fetchInvestments = async () => {
      try {
        const q = query(
          collection(db, "investments"),
          where("userId", "==", user.id)
        );
        const querySnapshot = await getDocs(q);
        const investmentsData = [];
        querySnapshot.forEach((doc) => {
          investmentsData.push({ id: doc.id, ...doc.data() });
        });
        setInvestments(investmentsData);
      } catch (error) {
        console.error("Error fetching investments data:", error);
      }
    };

    fetchInvestments();
  }, [user.id]);

  const assets = [
    // Asset list as provided
  ];

  const [isTopUpModalVisible, setIsTopUpModalVisible] = useState(false);
  const [isAssetModalVisible, setIsAssetModalVisible] = useState(false);
  const [isInvestModalVisible, setIsInvestModalVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const showModal = (asset = null, type = "") => {
    if (asset) {
      setSelectedAsset(asset);
      setIsTopUpModalVisible(false);
      setIsAssetModalVisible(true);
    } else if (type === "invest") {
      setIsInvestModalVisible(true);
    } else {
      setIsTopUpModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsTopUpModalVisible(false);
    setIsAssetModalVisible(false);
    setIsInvestModalVisible(false);
    setSelectedAsset(null);
  };

  const handleCancel = () => {
    setIsTopUpModalVisible(false);
    setIsAssetModalVisible(false);
    setIsInvestModalVisible(false);
    setSelectedAsset(null);
  };

  const getStatusTag = (status) => {
    let color;
    switch (status) {
      case "pending":
        color = "orange";
        break;
      case "failed":
        color = "red";
        break;
      case "successful":
        color = "green";
        break;
      default:
        color = "default";
    }
    return <Tag color={color}>{status}</Tag>;
  };

  const investmentColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amountDeposited",
      key: "amountDeposited",
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => getStatusTag(text),
    },
  ];
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Navigate to the login page or any other page after successful logout
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
      // Optionally, show a message to the user
    }
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="hidden lg:block">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 ml-0 lg:ml-[300px] transition-margin duration-300">
        <div className="tradingview-widget-container mb-4">
          <div className="tradingview-widget-container__widget"></div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
          <div className="col-span-1 lg:col-span-1 bg-white p-4 rounded-lg shadow">
            <div className="absolute top-20 left-4 lg:hidden">
              {" "}
              {/* Hide button on large screens */}
              <Button
                onClick={toggleDropdown}
                className="bg-blue-600 text-white"
              >
                <MenuOutlined /> {/* Ant Design menu icon */}
              </Button>
              {dropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white rounded-md text-center shadow-lg">
                  <ul className="py-1">
                    <Link to='/Dashboard'>
                    <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100">
                      Home
                    </li>
                    </Link>
                    <Link to='/InvestmentTracker'>
                    <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100">
                      Investments
                    </li>
                    </Link>
                    <Link to='/'>
                    <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100">
                      Messages
                    </li>
                    </Link>
                    {user.role === "admin" && (
                      <>
                        <Link to='/UsersList'>
                        <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100">
                          Users
                        </li>
                        </Link> 
                         <Link to='/InvestmentsTable'>
                        <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100">
                          Transaction
                        </li>
                        </Link>
                      </>
                    )}
                  

                    <li className="px-4 py-2 text-black cursor-pointer hover:bg-blue-100" onClick={handleLogout}>
                      Logout
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center p-4">
              <div className="flex flex-col items-center mb-4">
                <img
                  src={user?.passportPhoto || userimg} // replace with the actual image path or use a default
                  alt="Profile"
                  className="rounded-full w-24 h-24 mb-2"
                />
                <div className="text-4xl font-bold">
                  ${(user?.balance ?? 0).toFixed(2)}
                </div>
              </div>
              <div className="text-center mb-4">
                <div className="font-bold">
                  {user?.firstName} {user?.lastName}
                </div>
                <div className="text-gray-500">{user?.email}</div>
              </div>
              <div className="flex space-x-2 mt-4">
                <Button
                  type="primary"
                  onClick={() => showModal()}
                  className="bg-blue-600"
                >
                  Deposit
                </Button>
                <Button className="bg-gray-600 cursor-not-allowed hover:bg-slate-400">
                  Withdraw
                </Button>
                <Button
                  type="primary"
                  className="bg-blue-600"
                  onClick={() => showModal(null, "invest")}
                >
                  Invest
                </Button>
              </div>
            </div>
            <Modal
              title="Top Up Balance"
              visible={isTopUpModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <h1 className="text-2xl font-semibold">Tokens</h1>
              <div className="grid grid-cols-1 gap-4">
                {assets.map((asset) => (
                  <div
                    key={asset.symbol}
                    className="flex items-center p-4 text-black rounded-lg shadow cursor-pointer"
                    onClick={() => showModal(asset)}
                  >
                    <img
                      src={asset.image}
                      alt={asset.name}
                      className="w-10 h-10 mr-4"
                    />
                    <div className="flex-1">
                      <div className="font-bold">{asset.symbol}</div>
                      <div className="text-gray-400">
                        ≈ {asset.usd.toFixed(2)} USD
                      </div>
                    </div>
                    <div className="font-bold">{asset.balance}</div>
                  </div>
                ))}
              </div>
            </Modal>

            <Modal
              title="Choose Investment Type"
              visible={isInvestModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <div className="grid grid-cols-1 gap-4">
                {["Bonds", "Stocks", "Crypto", "Forex"].map((type) => (
                  <Button
                    key={type}
                    type="primary"
                    className="bg-blue-600 w-full"
                    onClick={() => {
                      // Navigate to the /invest page with the selected type
                      navigate(`/Invest?type=${type}`);
                      handleOk();
                    }}
                  >
                    {type}
                  </Button>
                ))}
              </div>
            </Modal>
          </div>
          <div className="col-span-1 lg:col-span-2 bg-white p-4 rounded-lg shadow">
            <h1 className="text-2xl font-semibold mb-4">Transactions</h1>
            <Table
              dataSource={investments}
              columns={investmentColumns}
              rowKey="id"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="col-span-1 lg:col-span-1 bg-white p-4 rounded-lg shadow">
            <h1 className="text-2xl font-semibold mb-4">Assets</h1>
            <div className="mb-4">
              <TradingViewMarketOverviewWidget />
            </div>
          </div>
          <div className="">
            <TradingViewWidgetNews />
          </div>
        </div>
      </div>
      {selectedAsset && (
        <Modal
          title={selectedAsset.name}
          visible={isAssetModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="text-center">
            <img
              src={selectedAsset.image}
              alt={selectedAsset.name}
              className="w-20 h-20 mx-auto"
            />
            <h2 className="text-2xl font-bold">{selectedAsset.symbol}</h2>
            <div className="text-4xl font-bold">
              {selectedAsset.balance} {selectedAsset.symbol}
            </div>
            <div className="text-gray-400">
              ≈ {selectedAsset.usd ? selectedAsset.usd.toFixed(2) : "N/A"} USD
            </div>
            <div className="flex justify-center mt-4">
              <Button type="primary" className="mr-2">
                Receive
              </Button>
              <Button type="default">Send</Button>
            </div>
            <div className="mt-4">
              Receiving Address: {selectedAsset.receivingAddress}
              <Button type="link" icon="copy" />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
