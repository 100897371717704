// Import the necessary Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDMKD3Bb40M2Oo9cS0MdFhZCgSnp7KT9B4",
  authDomain: "pure-trade-hub.firebaseapp.com",
  projectId: "pure-trade-hub",
  storageBucket: "pure-trade-hub.appspot.com",
  messagingSenderId: "918843966876",
  appId: "1:918843966876:web:7e39918c71d0b7df0d7459",
  measurementId: "G-QBMLQZV525"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth()
export default app
