import React, { useEffect, useState } from "react";
import { Table, Select, message, Modal } from "antd";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseconfig"; // Make sure the path is correct to your Firebase config;
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';

const { Option } = Select;

const InvestmentsTable = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  useEffect(() => {
    const fetchInvestments = async () => {
      const querySnapshot = await getDocs(collection(db, "investments"));
      const investmentsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        key: doc.id, // Ant Design requires a unique key for each row
      }));
      setData(investmentsData);
    };

    fetchInvestments();
  }, []);

  const updateStatus = async (investmentId, newStatus, amount, userId) => {
    try {
      const investmentRef = doc(db, "investments", investmentId);

      // Fetch current investment status
      const investmentDoc = await getDoc(investmentRef);
      const currentStatus = investmentDoc.exists()
        ? investmentDoc.data().status
        : null;

      // Update investment status
      await updateDoc(investmentRef, { status: newStatus });

      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const currentBalance = userDoc.data().balance || 0;

        if (newStatus === "successful" && currentStatus !== "successful") {
          // Update the user's balance (add amount)
          await updateDoc(userRef, { balance: currentBalance + amount });
          message.success("User balance updated successfully!");
        } else if (
          (newStatus === "failed" || newStatus === "pending") &&
          currentStatus === "successful"
        ) {
          // Update the user's balance (deduct amount)
          await updateDoc(userRef, { balance: currentBalance - amount });
          message.success("User balance deducted successfully!");
        }
      } else {
        message.error("User does not exist.");
      }

      message.success("Status updated successfully!");

      // Update the local state to reflect the change
      setData((prevData) =>
        prevData.map((item) =>
          item.key === investmentId ? { ...item, status: newStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating status: ", error);
      message.error("Failed to update status. Please try again.");
    }
  };

  const columns = [
    {
      title: "User Image",
      dataIndex: "passportPhoto",
      key: "passportPhoto",
      render: (url) => (
        <img
          src={url}
          alt="User"
          style={{ width: 50, height: 50, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Amount Deposited",
      dataIndex: "amountDeposited",
      key: "amountDeposited",
      render: (amount) => `$${amount.toFixed(2)}`, // Formatting amount with currency symbol
    },
    {
      title: "Investment Package",
      dataIndex: "investmentPackage",
      key: "investmentPackage",
    },
    {
      title: "Investment Option",
      dataIndex: "investmentOption",
      key: "investmentOption",
    },
    {
      title: "Proof of Payment",
      dataIndex: "proofOfPaymentURL",
      key: "proofOfPaymentURL",
      render: (url) => (
        <img
          src={url}
          alt="User"
          style={{
            width: 80,
            height: 50,
            borderRadius: "10%",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedImage(url);
            setIsModalVisible(true);
          }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Select
          defaultValue={text}
          style={{ width: 120 }}
          onChange={(value) =>
            updateStatus(
              record.key,
              value,
              record.amountDeposited,
              record.userId
            )
          }
        >
          <Option value="pending">Pending</Option>
          <Option value="successful">Successful</Option>
          <Option value="failed">Failed</Option>
        </Select>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
  ];

  return (
    <div style={{ padding: 24, marginLeft: 240, minHeight: "100vh" }}>
      {/* Modal for displaying full image */}

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        {selectedImage && (
          <Zoom>
            <img
              src={selectedImage}
              alt="Proof of Payment"
              style={{ width: "100%" }}
            />
          </Zoom>
        )}
      </Modal>
      {/* Adjust the marginLeft value as needed */}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }} // Adjust page size as needed
        scroll={{ x: 1000 }} // Allows horizontal scrolling for better responsiveness
        bordered
        style={{ width: "100%" }} // Ensure table uses full width of its container
      />
    </div>
  );
};

export default InvestmentsTable;
