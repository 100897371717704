// MainLayout.js
import React from "react";
import Sidebar from "../Sidebar/Sidebar";

const MainLayout = ({ children }) => {
  return (
    <div className="flex ">
      <div className="fixed">
        <Sidebar />
      </div>
      <div className="flex-1 min-h-screen bg-gray-100 p-4 md:p-8 lg:p-12">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
