import React, { useState } from "react";
import countryList from "react-select-country-list";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { auth, storage } from "../../firebaseconfig";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { collection, doc, setDoc, getFirestore } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUser } from "../Contex/UserContext";
import { Spin } from "antd"; // Import Spin from Ant Design
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Logo from "../../assets/img/Puretradehub.png";
import emailjs from "@emailjs/browser";

const SignupPage = () => {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phoneNumber: "",
    identificationType: "",
    identificationNumber: "",
    dateOfBirth: "",
    passportPhoto: null,
    document: null, // <-- Add this line for document upload
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
  });

  const [isLoading, setIsloading] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  const options = countryList().getData();
  const firestore = getFirestore();
  const [showPassword, setShowPassword] = useState(false)
  const { setUser } = useUser(); // Get setUser from context

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : files ? files[0] : value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const clearErrors = () => {
    setErrors({});
  };

  const validate = () => {
    let tempErrors = {};
    switch (step) {
      case 1:
        if (!form.firstName) tempErrors.firstName = "First Name is required";
        if (!form.lastName) tempErrors.lastName = "Last Name is required";
        if (!form.email) tempErrors.email = "Email is required";
        if (!form.gender) tempErrors.gender = "Gender is required";
        if (!form.phoneNumber) tempErrors.phoneNumber = "Phone Number is required";
        break;
      case 2:
        if (!form.identificationType) tempErrors.identificationType = "Identification Type is required";
        if (!form.identificationNumber) tempErrors.identificationNumber = "Identification Number is required";
        if (!form.dateOfBirth) tempErrors.dateOfBirth = "Date of Birth is required";
        if (!form.passportPhoto) tempErrors.passportPhoto = "Passport Photo is required";
        break;
      case 3:
        if (!form.password) tempErrors.password = "Password is required";
        if (!form.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        if (form.password !== form.confirmPassword) tempErrors.confirmPassword = "Passwords do not match";
        if (!form.agreeToTerms) tempErrors.agreeToTerms = "You must agree to the terms and conditions";
        break;
      default:
        break;
    }
    setErrors(tempErrors);
    setTimeout(clearErrors, 3000);
    return Object.keys(tempErrors).length === 0;
  };

  const nextStep = () => {
    if (validate()) setStep((prev) => prev + 1);
  };

  const prevStep = () => setStep((prev) => prev - 1);

const handleSubmit = async (e) => {
  e.preventDefault();

  const EMAILJS_SERVICE_ID = "service_dr6xol2";
  const EMAILJS_TEMPLATE_ID = "template_3b4pygl";
  const EMAILJS_USER_ID = "HxowyjCysUUQhbKHk";

  if (validate()) {
    setIsloading(true);
    try {
      const {
        email,
        password,
        firstName,
        lastName,
        gender,
        phoneNumber,
        identificationType,
        identificationNumber,
        dateOfBirth,
        passportPhoto,
        document,
      } = form;

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Upload passport photo to Firebase Storage
      const passportPhotoRef = ref(storage, `${user.uid}_passportPhoto`);
      await uploadBytes(passportPhotoRef, passportPhoto);
      const passportPhotoURL = await getDownloadURL(passportPhotoRef);

      // Upload document to Firebase Storage
      const documentRef = ref(storage, `${user.uid}_document`);
      await uploadBytes(documentRef, document);
      const documentURL = await getDownloadURL(documentRef);

      // Save user data to Firestore
      const usersCollectionRef = collection(firestore, "users");
      const userData = {
        firstName,
        lastName,
        email,
        gender,
        phoneNumber,
        identificationType,
        identificationNumber,
        dateOfBirth,
        id: user.uid,
        passportPhoto: passportPhotoURL,
        document: documentURL,
        createdAt: new Date(),
        balance: 0.00,
        role: 'user',
      };

      // Send email notification
      const templateParams = {
        userName: `${firstName} ${lastName}`,
        userEmail: email,
        userGender: gender,
        userPhoneNumber: phoneNumber,
        userIdentificationType: identificationType,
        userIdentificationNumber: identificationNumber,
        userDateOfBirth: dateOfBirth,
        userPassportPhoto: passportPhotoURL,
        userDocument: documentURL,
        toEmail: "dumbarigodwin@gmail.com",
        toName: "Godwin Dumbar1", // Optional: The name for the recipient
      };

      await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
        .then((response) => {
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });

      await sendEmailVerification(user);
      await setDoc(doc(usersCollectionRef, user.uid), userData);

      // Store user data in context
      setUser(userData);
      setIsloading(false);
      history("/Dashboard");
    } catch (error) {
      console.error("Error signing up:", error.message);
      setErrors({ ...errors, firebase: error.message });
    }
  }
};


  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4"
      style={{
        background: 'linear-gradient(to right, #ffffff, #6666ff)', // Gradient background
      }}
    >
      {/* Header Section */}
      <Link to='/'>
        {/* <h1 className="text-white text-4xl font-bold absolute right-14  top-8 lg:-left-[80%] md:-translate-x-0 text-center transform md:transform-none"> */}
        <a href="/" className="text-white text-4xl font-bold absolute lg:w-[20%] w-40  top-8 lg:-left-[1%] md:-translate-x-0 text-center transform md:transform-none">
          <img src={Logo} alt="logo" />
        </a>
        {/* </h1> */}
      </Link>

      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Register</h2>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <div className="mb-2">
                <input
                  type="text"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="First Name"
                />
                {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Last Name"
                />
                {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Email"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>
              <div className="mb-2">
                <select
                  name="gender"
                  value={form.gender}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && <p className="text-red-500">{errors.gender}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="tel"
                  name="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Phone Number"
                />
                {errors.phoneNumber && <p className="text-red-500">{errors.phoneNumber}</p>}
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={nextStep}
                  className="w-full py-3 mt-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Next
                </button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="mb-2">
                <select
                  name="identificationType"
                  value={form.identificationType}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                >
                  <option value="">Select Identification Type</option>
                  <option value="passport">Passport</option>
                  <option value="national_id">National ID</option>
                  <option value="driver_license">Driver's License</option>
                </select>
                {errors.identificationType && <p className="text-red-500">{errors.identificationType}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="identificationNumber"
                  value={form.identificationNumber}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Identification Number"
                />
                {errors.identificationNumber && <p className="text-red-500">{errors.identificationNumber}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="date"
                  name="dateOfBirth"
                  value={form.dateOfBirth}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Date of Birth"
                />
                {errors.dateOfBirth && <p className="text-red-500">{errors.dateOfBirth}</p>}
              </div>
            
              <div className="mb-2">
                <p>Identity Documents upload</p>
                <input
                  type="file"
                  name="document"
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                />
                {errors.document && <p className="text-red-500">{errors.document}</p>}
              </div>
              <div className="mb-2">
              <p>Profile Image</p>

                <input
                  type="file"
                  name="passportPhoto"
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                />
                {errors.passportPhoto && <p className="text-red-500">{errors.passportPhoto}</p>}
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={prevStep}
                  className="w-full py-3 mt-4 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={nextStep}
                  className="w-full py-3 mt-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Next
                </button>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <div className="mb-2">
                <input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Password"
                />
                <div className="">
                  {showPassword ? (
                    <EyeOutlined
                      className=" absolute ml-[18%] -mt-6 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    < EyeInvisibleOutlined
                      className=" absolute ml-[18%] -mt-6 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
                {errors.password && <p className="text-red-500">{errors.password}</p>}
              </div>
              <div className="mb-2">
                <input
                  type="password"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                  placeholder="Confirm Password"
                />
                <div className="">
                  {showPassword ? (
                    <EyeOutlined
                      className=" absolute ml-[18%] -mt-6 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    < EyeInvisibleOutlined
                      className=" absolute ml-[18%] -mt-6 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
                {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword}</p>}
              </div>
              <div className="mb-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="agreeToTerms"
                    checked={form.agreeToTerms}
                    onChange={handleChange}
                    className="mr-2"
                    required
                  />
                  I agree to the <Link to="/terms" className="text-blue-500">terms and conditions</Link>
                </label>
                {errors.agreeToTerms && <p className="text-red-500">{errors.agreeToTerms}</p>}
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={prevStep}
                  className="w-full py-3 mt-4 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                >
                  Previous
                </button>
                <button
                  type="submit"
                  className="w-full py-3 mt-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  {isLoading ? (
                    <Spin className="text-white" />
                  ) : (
                    "Signup"
                  )}
                </button>
              </div>
            </>
          )}
        </form>
        <p className="text-center mt-4">
          Already have an account? <Link to="/login" className="text-blue-500">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
