import React, { useEffect, useState } from "react";
import { Table, Tag, notification } from "antd";
import { db } from "../firebaseconfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useUser } from "../components/Contex/UserContext";

const InvestmentTracker = () => {
  const { user } = useUser();
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const q = query(
          collection(db, "investments"),
          where("userId", "==", user.id)
        );

        const querySnapshot = await getDocs(q);
        const userInvestments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setInvestments(userInvestments);
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to fetch investments. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchInvestments();
    }
  }, [user]);

  const columns = [
    {
      title: "Investment Option",
      dataIndex: "investmentOption",
      key: "investmentOption",
    },
    {
      title: "Package",
      dataIndex: "investmentPackage",
      key: "investmentPackage",
    },
    {
      title: "Amount Deposited (USD)",
      dataIndex: "amountDeposited",
      key: "amountDeposited",
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: "Remark",
      key: "remark",
      render: () => (
        <Tag  key="ongoing" className="animate-pulse bg-green-600">
          Ongoing
        </Tag>
      ),
    },
  ];

  return (
    <div className="min-h-screen ml-80 bg-gray-100 p-4 md:p-8 lg:p-12">
      <div className="container mx-auto">
        <h1 className="text-3xl md:text-4xl font-semibold mb-4 text-center ">
          Your Investments
        </h1>
        <Table
          columns={columns}
          dataSource={investments}
          rowKey="id"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default InvestmentTracker;
