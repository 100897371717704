import React from "react";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

const NavMobile = ({ setNavMobile }) => {
  const handleClick = () => {
    setNavMobile(false);
  };

  return (
    <nav className="lg:hidden bg-white h-full w-80 shadow-lg relative flex justify-center items-center">
      {/* Close Button */}
      <div
        onClick={handleClick}
        className="absolute top-4 left-4 cursor-pointer text-gray-500 hover:text-blue-600 transition-colors"
      >
        <CgClose className="text-3xl" />
      </div>

      {/* Menu List */}
      <ul className="text-lg font-medium flex flex-col gap-y-6">
        <li>
          <Link 
            to='/login' 
            className="block px-6 py-3 text-blue-600 border border-transparent rounded-lg transition-all hover:bg-blue-100 hover:shadow-lg hover:border-blue-600"
          >
            Login
          </Link>
        </li>
        <li>
          <Link 
            to='/signup' 
            className="block px-6 py-3 text-blue-600 border border-transparent rounded-lg transition-all hover:bg-blue-100 hover:shadow-lg hover:border-blue-600"
          >
            Sign Up
          </Link>
        </li>
        <li>
          <Link 
            to='/about' 
            className="block px-6 py-3 text-blue-600 border border-transparent rounded-lg transition-all hover:bg-blue-100 hover:shadow-lg hover:border-blue-600"
          >
            About
          </Link>
        </li>
        <li>
          <Link to='/contact'
            className="block px-6 py-3 text-blue-600 border border-transparent rounded-lg transition-all hover:bg-blue-100 hover:shadow-lg hover:border-blue-600 cursor-pointer"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavMobile;
