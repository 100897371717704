import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebaseconfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDoc } from "firebase/firestore";
import "./Auth.css";
import { useUser } from "../Contex/UserContext";
import { db } from "../../firebaseconfig"; // Ensure this path is correct
import { Spin } from "antd"; // Import Spin from Ant Design
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Logo from "../../assets/img/Puretradehub.png";


const LoginPage = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { setUser } = useUser(); // Get setUser from context
  const usersCollectionRef = collection(db, "users");
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const clearErrors = () => {
    setErrors({});
  };

  const validate = () => {
    let tempErrors = {};
    if (!form.email) tempErrors.email = "Email is required";
    if (!form.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    setTimeout(clearErrors, 3000);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsloading(true);
      try {
        const { email, password } = form;
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        // Fetch user data from Firestore
        const userDocRef = doc(usersCollectionRef, user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(userData);
        } else {
          console.error("No such user document!");
        }

        navigate("/Dashboard"); // Navigate to Dashboard after successful login
      } catch (error) {
        console.error("Error logging in:", error.message);
        setErrors({ ...errors, firebase: error.message });
      } finally {
        setIsloading(false);
      }
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4"
      style={{
        background: 'linear-gradient(to right, #ffffff, #6666ff)', // Gradient background
      }}
    >
      {/* Header Section */}
      <Link to='/'>
      {/* <h1 className="text-white text-4xl font-bold absolute right-14  top-8 lg:-left-[80%] md:-translate-x-0 text-center transform md:transform-none"> */}
      <a href="/" className="text-white text-4xl font-bold absolute lg:w-[20%] w-40  top-8 lg:-left-[1%] md:-translate-x-0 text-center transform md:transform-none">
          <img src={Logo} alt="logo" />
        </a>
      {/* </h1> */}
      </Link>

      {/* Login Form Section */}
      <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-8 mt-24 md:mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 outline-none transition duration-200"
              required
              placeholder="Email"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          <div className="mb-4 relative">
  <input
    type={showPassword ? "text" : "password"}
    name="password"
    value={form.password}
    onChange={handleChange}
    className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 outline-none transition duration-200"
    required
    placeholder="Password"
  />
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
    {showPassword ? (
      <EyeOutlined
        className="text-gray-400 cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
      />
    ) : (
      <EyeInvisibleOutlined
        className="text-gray-400 cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
      />
    )}
  </div>

  {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
</div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full py-3 mt-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
            >
              {isLoading ? (
                <Spin className="text-white"/>  
              ) : (
                "Login"
              )}
            </button>
          </div>
        </form>
        {errors.firebase && <p className="text-red-500 text-center mt-4">{errors.firebase}</p>}
        <p className="text-center mt-6 text-gray-600">
          Don't have an account? <Link to="/signup" className="text-blue-500 hover:text-blue-700 transition duration-200">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
