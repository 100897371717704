import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseconfig"; // Ensure this path is correct
import { Table, Button, Popconfirm, message, Modal, Form, Input } from "antd";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [balance, setBalance] = useState("");
  const [isImageModalVisible, setIsImageModalVisible] = useState(false); // For image modal
  const [imageUrl, setImageUrl] = useState(""); // Store clicked image URL

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleView = (user) => {
    setSelectedUser(user);
    setBalance(user.balance); // Initialize balance input with user's current balance
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
    setBalance(""); // Reset balance input
  };

  const handleBalanceChange = (e) => {
    setBalance(e.target.value);
  };

  const handleUpdateBalance = async () => {
    if (selectedUser) {
      try {
        const userDoc = doc(db, "users", selectedUser.id);
        await updateDoc(userDoc, { balance: parseFloat(balance) });

        // Update user list state with new balance
        setUsers(
          users.map((user) =>
            user.id === selectedUser.id
              ? { ...user, balance: parseFloat(balance) }
              : user
          )
        );
        message.success(
          `Balance updated for ${selectedUser.firstName} ${selectedUser.lastName}`
        );
        handleModalClose();
      } catch (error) {
        console.error("Error updating balance:", error);
        message.error("Failed to update balance");
      }
    }
  };

  const handleEdit = (user) => {
    message.info(`Editing user ${user.firstName} ${user.lastName}`);
    // Implement edit logic here
  };

  const handleDelete = async (user) => {
    try {
      await deleteDoc(doc(db, "users", user.id));
      setUsers(users.filter((u) => u.id !== user.id));
      message.success(
        `User ${user.firstName} ${user.lastName} deleted successfully`
      );
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error(`Failed to delete user ${user.firstName} ${user.lastName}`);
    }
  };

  const handleImageClick = (url) => {
    setImageUrl(url);
    setIsImageModalVisible(true); // Show image modal
  };

  const columns = [
    {
      title: "User Image",
      dataIndex: "passportPhoto",
      key: "passportPhoto",
      render: (url) => (
        <img
          src={url}
          alt="User"
          style={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => handleImageClick(url)} // Show full image on click
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => `$${(balance ?? 0).toFixed(2)}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, user) => (
        <div>
          <Button type="link" onClick={() => handleView(user)}>
            View
          </Button>
          <Button type="link" onClick={() => handleEdit(user)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDelete(user)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="min-h-screen ml-80 bg-gray-100 p-4 md:p-8 lg:p-12">
      <div className="container mx-auto">
        <h1 className="text-3xl md:text-4xl font-semibold mb-4 text-center">
          Users
        </h1>
        <Table
          dataSource={users}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 5 }}
        />

        {/* User Details Modal */}
        {selectedUser && (
          <Modal
          title="User Details"
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
            <Button key="update" className="bg-blue-500 hover:bg-blue-600 text-white" onClick={handleUpdateBalance}>
              Update Balance
            </Button>,
          ]}
        >
          {/* User Image */}
          {selectedUser.passportPhoto && (
            <div className="flex justify-center mb-6">
              <img
                src={selectedUser.passportPhoto}
                alt={`${selectedUser.firstName} ${selectedUser.lastName}`}
                className="w-24 h-24 rounded-full shadow-lg"
              />
            </div>
          )}
        
          {/* User Details */}
          <div className="space-y-3 text-gray-700">
            <p>
              <span className="font-semibold">First Name:</span> {selectedUser.firstName}
            </p>
            <p>
              <span className="font-semibold">Last Name:</span> {selectedUser.lastName}
            </p>
            <p>
              <span className="font-semibold">Email:</span> {selectedUser.email}
            </p>
            <p>
              <span className="font-semibold">Phone Number:</span> {selectedUser.phoneNumber}
            </p>
            <p>
              <span className="font-semibold">Gender:</span> {selectedUser.gender}
            </p>
            <p>
              <span className="font-semibold">Identification Type:</span> {selectedUser.identificationType}
            </p>
            <p>
              <span className="font-semibold">Identification Number:</span> {selectedUser.identificationNumber}
            </p>
            <p>
              <span className="font-semibold">Date of Birth:</span> {selectedUser.dateOfBirth}
            </p>
            <p>
              <span className="font-semibold">Balance:</span> ${parseFloat(selectedUser.balance).toFixed(2)}
            </p>
          </div>
        
          {/* Document Download */}
          {selectedUser.document && (
            <div className="mt-4">
              <p>
                <span className="font-semibold">Document:</span>{" "}
                <a
                  href={selectedUser.document}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                  download
                >
                  Download Document
                </a>
              </p>
            </div>
          )}
        
          {/* Balance Update Form */}
          <Form layout="vertical" className="mt-6">
            <Form.Item label="Balance" className="text-gray-700">
              <Input
                type="number"
                value={balance}
                onChange={handleBalanceChange}
                className="rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              />
            </Form.Item>
          </Form>
        </Modal>
        
        )}

        {/* Image Modal for Full View */}
        <Modal
          visible={isImageModalVisible}
          footer={null}
          onCancel={() => setIsImageModalVisible(false)}
        >
          <img src={imageUrl} alt="Full Size" style={{ width: "100%" }} />
        </Modal>
      </div>
    </div>
  );
};

export default UsersList;
