import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer";
import Header from "../header/Header";
import NavMobile from "../nav/NavMobile";

function About() {
  const [navMobile, setNavMobile] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000, delay: 300 });
  }, []);

  return (
    <>
      <Header setNavMobile={setNavMobile} />
      {/* Mobile Nav */}
      <div className={`${navMobile ? 'right-0' : '-right-full'} fixed z-10 top-0 h-full transition-all duration-200`}>
        <NavMobile setNavMobile={setNavMobile} />
      </div>

      <div className="bg-white min-h-screen py-10">
        <div className="container mx-auto px-4">
          {/* Image Section at the top */}
          <div className="w-full mb-8" data-aos="fade-down">
            <img
              src="https://t3.ftcdn.net/jpg/01/28/44/76/360_F_128447604_6deYSrg6bgH2F3YaoU0icdhvxNu4ReDN.jpg"
              alt="Investment"
              className="rounded-lg shadow-lg w-full max-w-5xl mx-auto h-32 object-cover"
            />
          </div>

          {/* Main Content Section */}
          <div className="flex flex-col items-center justify-between">
            {/* Text Section */}
            <div className="w-full p-4" data-aos="fade-left">
              <h1 className="text-4xl font-bold text-blue-600 mb-6 text-center">
                About PureTrade Hub
              </h1>
              <p className="text-lg text-gray-700 mb-8 text-center">
                PureTrade Hub is a leading investment platform designed to
                empower individuals and organizations to achieve their financial
                goals. We offer a variety of investment options tailored to your
                needs.
              </p>
              <h2 className="text-3xl font-semibold text-blue-600 mb-4 text-center">
                Our Mission
              </h2>
              <p className="text-lg text-gray-700 mb-6 text-center">
                At PureTrade Hub, our mission is to provide secure and
                innovative investment solutions that enable our clients to grow
                their wealth and secure their future. We believe in
                transparency, reliability, and customer satisfaction.
              </p>
              <h2 className="text-3xl font-semibold text-blue-600 mb-4 text-center">
                Why Choose Us?
              </h2>
              <p className="text-lg text-gray-700 text-center">
                With a team of experienced financial experts and a commitment to
                delivering high-quality service, PureTrade Hub is the trusted
                choice for savvy investors. Join us today and take the first
                step towards a brighter financial future.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default About;
