import React, { useEffect, useRef } from "react";

const TradingViewWidgetNews = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    // Clear the widget container to avoid duplication
    if (widgetRef.current) {
      widgetRef.current.innerHTML = "";
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: "all_symbols",
      isTransparent: false,
      displayMode: "compact",
      width: "100%",
      height: "100%", // This allows the container to manage the height
      colorTheme: "light",
      locale: "en",
    });
    widgetRef.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container md:w-[200%] h-96 md:h-[600px]">
      <div
        ref={widgetRef}
        className="tradingview-widget-container__widget w-full h-full"
      ></div>
    </div>
  );
};

export default TradingViewWidgetNews;
