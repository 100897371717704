import React, { useState } from "react";
import {
  Button,
  Modal,
  Input,
  notification,
  Upload,
  Select,
  Progress,
} from "antd";
import { UploadOutlined, CopyOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db, storage } from "../../firebaseconfig";
import { addDoc, collection } from "firebase/firestore";
import { useUser } from "../Contex/UserContext";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";

const { Option } = Select;

const stockPackages = [
  {
    name: "BASIC STOCK",
    profit: "5%",
    minDeposit: "1000 USD",
    maxDeposit: "5000 USD",
    image:
      "https://newlinefirm.com/wp-content/uploads/2022/08/basic-package_.png",
  },
  {
    name: "PREMIUM STOCK",
    profit: "7%",
    minDeposit: "5000 USD",
    maxDeposit: "20000 USD",
    image:
      "https://hiredpartners.com/wp-content/uploads/2023/10/premium_package.png",
  },
  {
    name: "VIP STOCK",
    profit: "15%",
    minDeposit: "20000 USD",
    maxDeposit: "UNLIMITED",
    image:
      "https://img1.wsimg.com/isteam/ip/d27699e0-08fd-44e5-8e61-339b2ebab41c/ols/VIP-Logo.png/:/rs=w:1200,h:1200",
  },
];

const forexPackages = [
  {
    name: "BASIC FOREX",
    profit: "3%",
    minDeposit: "500 USD",
    maxDeposit: "2000 USD",
    image:
      "https://files.selar.co/product-images/2023/products/kenzy-smallz1/basic-package-selar.co-656633d5d84ca.png",
  },
  {
    name: "ADVANCED FOREX",
    profit: "5%",
    minDeposit: "2000 USD",
    maxDeposit: "10000 USD",
    image:
      "https://thumbs.dreamstime.com/b/forex-logo-kaarsenkaartverkoop-207598040.jpg",
  },
  {
    name: "PRO FOREX",
    profit: "10%",
    minDeposit: "10000 USD",
    maxDeposit: "UNLIMITED",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdo27qI2dPsvERETzOZ5vXZ9oitaZiNu8ugFhrTOehkW9Ct8_b0KyRVNRlqzOd62wjQCw&usqp=CAU",
  },
];

const bondPackages = [
  {
    name: "BASIC BOND",
    profit: "20%",
    minDeposit: "1000 USD",
    maxDeposit: "5000 USD",
    image:
      "https://images.jdmagicbox.com/quickquotes/images_main/basic-package-2020062659-03x4k5ap.png",
  },
  {
    name: "PREMIUM BOND",
    profit: "50%",
    minDeposit: "5000 USD",
    maxDeposit: "20000 USD",
    image:
      "https://hiredpartners.com/wp-content/uploads/2023/10/premium_package.png",
  },
  {
    name: "VIP BOND",
    profit: "90%",
    minDeposit: "20000 USD",
    maxDeposit: "UNLIMITED",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqf4mgto2weOA7V6ZBzsVOysj2dMHktW9IGA&s",
  },
];

const cryptoPackages = [
  {
    name: "BASIC CRYPTO",
    profit: "6%",
    minDeposit: "1000 USD",
    maxDeposit: "5000 USD",
    image:
      "https://static1.squarespace.com/static/5c2864609f8770b74f18cbb8/5c4f86bcc2241b20c1a16ebd/5c4f88b670a6ada5330e4012/1712777140659/basicpackage.jpg?format=1500w",
  },
  {
    name: "PREMIUM CRYPTO",
    profit: "8%",
    minDeposit: "5000 USD",
    maxDeposit: "20000 USD",
    image:
      "https://hiredpartners.com/wp-content/uploads/2023/10/premium_package.png",
  },
  {
    name: "VIP CRYPTO",
    profit: "18%",
    minDeposit: "20000 USD",
    maxDeposit: "UNLIMITED",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6PCO_ym6InjhODyso8HvZ7qyYZ_nEua0iQW4gI04n72QUZhQ-W4fQekRTW5RU6IT2WS8&usqp=CAU",
  },
];

const cryptos = ["Bitcoin", "Ethereum", "Ripple", "Litecoin"];
const stocks = ["Apple", "Google", "Amazon", "Tesla"];
const bonds = ["US Treasury", "Corporate Bond", "Municipal Bond"];

const InvestmentOffers = () => {
  const { user } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedInvestmentOption, setSelectedInvestmentOption] =
    useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [depositAmount, setDepositAmount] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [uploadProgress, setUploadProgress] = useState(0);
  const investmentType = searchParams.get("type");

  const [fileURL, setFileURL] = useState("");

  const showModal = (pkg) => {
    setSelectedPackage(pkg);
    setSelectedPaymentMethod(null);
    setDepositAmount("");
    setFile(null);
    setUploadProgress(0); // Reset upload progress when opening modal
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!selectedPackage) {
      notification.warning({
        message: "Warning",
        description: "Please select an investment package.",
      });
      return;
    }

    if (!selectedInvestmentOption) {
      notification.warning({
        message: "Warning",
        description: `Please select a ${investmentType}.`,
      });
      return;
    }

    const minDeposit = parseFloat(
      selectedPackage.minDeposit.replace(" USD", "")
    );
    const maxDeposit =
      selectedPackage.maxDeposit === "UNLIMITED"
        ? Infinity
        : parseFloat(selectedPackage.maxDeposit.replace(" USD", ""));
    const deposit = parseFloat(depositAmount);

    if (isNaN(deposit)) {
      notification.warning({
        message: "Warning",
        description: "Please enter a valid deposit amount.",
      });
      return;
    }
    if (deposit < minDeposit) {
      notification.warning({
        message: "Warning",
        description: `Deposit amount must be at least ${minDeposit} USD.`,
      });
      return;
    }
    if (deposit > maxDeposit) {
      notification.warning({
        message: "Warning",
        description: `Deposit amount cannot exceed ${
          maxDeposit === Infinity ? "unlimited" : maxDeposit
        } USD.`,
      });
      return;
    }

    setIsModalVisible(false);
    setIsConfirmModalVisible(true);
  };

  const handleConfirmOk = async () => {
    if (!file) {
      notification.warning({
        message: "Warning",
        description: "Please upload proof of payment.",
      });
      return;
    }

    try {
      // Initialize Firebase Storage
      const storage = getStorage();

      // Create a storage reference
      const storageRef = ref(storage, `proof-of-payments/${file.name}`);

      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);

      // Get the download URL of the uploaded file
      const fileURL = await getDownloadURL(storageRef);

      // Add document to Firestore with the URL of the uploaded file
      await addDoc(collection(db, "investments"), {
        username: `${user.firstName} ${user.lastName}`,
        email: user.email,
        phoneNumber: user.phoneNumber,
        passportPhoto: user.passportPhoto,
        amountDeposited: parseFloat(depositAmount),
        investmentPackage: selectedPackage.name,
        investmentOption: selectedInvestmentOption,
        status: "pending",
        userId: user.id,
        type: investmentType || "Investment",
        proofOfPaymentURL: fileURL, // Add the uploaded file URL to Firestore
      });

      // Show success notification
      notification.success({
        message: "Success",
        description:
          "Deposit Successfully Submitted. Please Wait For Confirmation.",
      });

      // Reset form states
      setIsConfirmModalVisible(false);
      setSelectedPackage(null);
      setSelectedInvestmentOption(null);
      setSelectedPaymentMethod(null);
      setDepositAmount("");
      setFile(null);
      setFileURL(""); // Reset file URL

      // Navigate to Dashboard
      navigate("/Dashboard");
    } catch (error) {
      // Show error notification
      notification.error({
        message: "Error",
        description: "Failed to submit investment details. Please try again.",
      });
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsConfirmModalVisible(false);
    setSelectedPackage(null);
    setSelectedInvestmentOption(null);
    setSelectedPaymentMethod(null);
    setDepositAmount("");
    setFile(null);
    setUploadProgress(0); // Reset upload progress
    setFileURL("");
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
    if (selectedFile) {
      // Create a storage ref
      const storageRef = ref(storage, `uploads/${selectedFile.name}`);

      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // You can handle the progress here if needed
        },
        (error) => {
          notification.error({
            message: "Upload Error",
            description: "Failed to upload file. Please try again.",
          });
        },
        () => {
          // Upload completed successfully, now get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFileURL(downloadURL);
            notification.success({
              message: "Upload Complete",
              description: "File uploaded successfully.",
            });
          });
        }
      );
    }
  };

  const getPackages = () => {
    switch (investmentType) {
      case "Stocks":
        return stockPackages;
      case "Forex":
        return forexPackages;
      case "Bonds":
        return bondPackages;
      case "Crypto":
        return cryptoPackages;
      default:
        return [];
    }
  };

  const getInvestmentOptions = () => {
    switch (investmentType) {
      case "Stocks":
        return stocks;
      case "Bonds":
        return bonds;
      case "Crypto":
        return cryptos;
      default:
        return [];
    }
  };

  const packages = getPackages();
  const investmentOptions = getInvestmentOptions();

  const walletAddresses = {
    "USDT TRC20": "35Ej1smV7SVGLt74fppbcvbLu6fT2QjUgX",
    "Tron TRX": "TF3e9ZKSsKeprfPVCXhoA9L8hgHocd5YXG",
    "Doge address": "0xBF70cb4fa54DA86378169fb45338F3b138508d42",
    "BNB address": "0xBF70cb4fa54DA86378169fb45338F3b138508d42",
    BTC: "35Ej1smV7SVGLt74fppbcvbLu6fT2QjUgX",
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        notification.success({
          message: "Copied",
          description:
            "The address has been copied to your clipboard. Please make the EXACT AMOUNT inputed below and upload the evidience on the next page",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Failed to copy address. Please try again.",
        });
      });
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex-1 min-h-screen bg-gray-100 p-4 md:p-8 lg:p-12 lg:ml-96 mt-28 lg:mt-0">
        <div className="container mx-auto">
          <h1 className="text-3xl md:text-4xl font-semibold mb-4 text-center">
            INVESTMENT OFFERS
          </h1>
          {investmentType && (
            <p className="text-center text-lg font-bold text-blue-600">
              Selected Investment Type: {investmentType}
            </p>
          )}
          <p className="text-center mb-8 text-sm md:text-base">
            You cannot withdraw from your principal balance until at least one
            month has passed from the deposit date. However, you can withdraw
            the profits earned during this period.
          </p>

          {packages.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
              {packages.map((pkg, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md  text-center transition-transform transform hover:scale-105"
                >
                  <img
                    src={pkg.image}
                    alt={pkg.name}
                    className="w-40 h-40 mx-auto mb-4"
                  />
                  <h2 className="text-2xl font-bold mb-2">{pkg.name}</h2>
                  <p className="text-lg font-semibold text-green-600 mb-2">
                    Profit: {pkg.profit}
                  </p>
                  <p className="text-gray-700 mb-2">
                    Min Deposit: {pkg.minDeposit}
                  </p>
                  <p className="text-gray-700 mb-4">
                    Max Deposit: {pkg.maxDeposit}
                  </p>
                  <Button
                    type="primary"
                    onClick={() => showModal(pkg)}
                    className="w-full bg-blue-500"
                  >
                    Invest Now
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-lg text-red-600">
              No packages available for the selected investment type.
            </p>
          )}
        </div>
      </div>

      <Modal
        title={`Deposit Funds for ${selectedPackage}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="bg-blue-500"
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        {selectedPackage && (
          <>
            <h2 className="text-lg font-semibold mb-4">
              Selected Package: {selectedPackage.name}
            </h2>
            <Select
              placeholder={`Select ${investmentType}`}
              value={selectedInvestmentOption}
              onChange={(value) => setSelectedInvestmentOption(value)}
              className="w-full mb-4"
            >
              {investmentOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
            <Input
              placeholder="Enter Deposit Amount"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              className="mb-4"
            />
            <Select
              placeholder="Select Payment Method"
              value={selectedPaymentMethod}
              onChange={(value) => setSelectedPaymentMethod(value)}
              className="w-full mb-4"
            >
              {Object.keys(walletAddresses).map((method) => (
                <Option key={method} value={method}>
                  {method}
                </Option>
              ))}
            </Select>
            {selectedPaymentMethod && (
              <div className="mb-4">
                <h2 className="text-lg font-semibold mb-2">
                  {selectedPaymentMethod} Wallet Address
                </h2>
                <p className="text-gray-700 mb-2">
                  Please make the payment to the following address:
                </p>
                <Input
                  value={walletAddresses[selectedPaymentMethod]}
                  addonAfter={
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() =>
                        handleCopyAddress(
                          walletAddresses[selectedPaymentMethod]
                        )
                      }
                    >
                      Copy
                    </Button>
                  }
                  readOnly
                  className="mb-2"
                />
              </div>
            )}
          </>
        )}
      </Modal>

      <Modal
        title="Confirm Deposit"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmOk}>
            Confirm
          </Button>,
        ]}
      >
        <p>Deposit Amount: {depositAmount} USD</p>
        <p>Selected Package: {selectedPackage?.name}</p>
        <p>
          Selected {investmentType}: {selectedInvestmentOption}
        </p>
        <p>Payment Method: {selectedPaymentMethod}</p>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleConfirmOk}>Confirm</button>
        {file && (
          <p className="mt-2 text-green-600">File Selected: {file.name}</p>
        )}
        {uploadProgress > 0 && (
          <Progress percent={uploadProgress} className="mt-2" />
        )}
        {fileURL && (
          <div className="mt-2">
            <p className="text-green-600">File Uploaded Successfully!</p>
            <img
              src={fileURL}
              alt="Proof of Payment"
              className="w-full h-auto mt-2"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default InvestmentOffers;
