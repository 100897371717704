import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from "./components/Home/Home";
import SignupPage from "./components/Auth/Auth";
import InvestmentOffers from "./components/Dashboard/Investment";
import { UserProvider } from "./components/Contex/UserContext";
import LoginPage from "./components/Auth/Login";
import MainLayout from "./components/hero/MainLayout ";
import UsersList from "./components/Users/Users";
import InvestmentsTable from "./Transactions/Invest";
import InvestmentTracker from "./Transactions/Tracker";
import About from "./components/sections/About";
import EmailVerification from "./components/Emailverify";
import FeaturesScreen from "./components/sections/Featuress";
import ContactUs from "./components/sections/Contact";

const App = () => {
  return (
    <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/About" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/features" element={<FeaturesScreen />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Invest" element={<MainLayout><InvestmentOffers /></MainLayout>} />
          <Route path="/UsersList" element={<MainLayout><UsersList /></MainLayout>} />
          <Route path="/InvestmentsTable" element={<MainLayout><InvestmentsTable /></MainLayout>} />
          <Route path="/InvestmentTracker" element={<MainLayout><InvestmentTracker /></MainLayout>} />
          <Route path="/EmailVerification" element={<MainLayout><EmailVerification /></MainLayout>} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
    </UserProvider>
  );
};

export default App;
